<template>
  <div class="page15">
    <img class="page15-img" src="../../assets/pages/obj_15.png" alt="">
    <div class="btns">
      <button type="button" @click="$router.push('/agree')"><img class="t-btn" src="../../assets/pages/btn01.png"></button>
      <button type="button" @click="$router.push('/estimate/clean')"><img class="t-btn" src="../../assets/pages/btn02.png"></button>
      <button type="button" @click="$router.push('/estimate/manage')"><img class="t-btn" src="../../assets/pages/btn03.png"></button>
      <button type="button" @click="$router.push('/akiya')"><img class="t-btn" src="../../assets/pages/btn04.png"></button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'page15',
}
</script>

<style scoped lang="scss">
.page15 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .page15-img {
    position: relative;
    height: 100%;
  }

  .btns {
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 40%;
    top: 41%;
    left: 45%;
  }

  button {
    height: 23%;
    margin-top: 2%;
  }

  .t-btn {
    height: 100%;
  }
}
</style>